<template>
  <div class="vehicle-stat-view">
    <div class="child-page-title">
      <h3>车辆关键指标</h3>
    </div>
    <div class="stat-context">
      <div class="context">
        <div>
          <img src="../../../assets/image/kjgq.png">
          <dv-digital-flop :config="inConfig" class="dv-digital-flop" />
          <div>今日进场车辆</div>
        </div>
        <div>
          <img src="../../../assets/image/kjgq3.png">
          <dv-digital-flop :config="outConfig" class="dv-digital-flop" />
          <div>今日出场车辆</div>
        </div>
        <div>
          <img src="../../../assets/image/kjgq2.png">
          <dv-digital-flop :config="stayingConfig" class="dv-digital-flop" />
          <div>今日未离场车辆</div>
        </div>
        <div>
          <img src="../../../assets/image/kjgq4.png">
          <dv-digital-flop
            :config="totalStayingConfig"
            class="dv-digital-flop"
          />
          <div>总未离场车辆</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getPastVehicleCount } from '@/api/vehicle'
export default {
  name: 'VehicleStatView',
  data() {
    return {
      inConfig: null,
      outConfig: null,
      stayingConfig: null,
      totalStayingConfig: null
    }
  },
  mounted() {
    this.removeVehicleStat()
  },
  methods: {
    /**
     * 获取施工人员劳务指标
     */
    removeVehicleStat() {
      getPastVehicleCount().then((res) => {
        const { data } = res
        this.inConfig = {
          number: [data.entered],
          style: {
            fill: '#029FEE'
          }
        }
        this.outConfig = {
          number: [data.exited],
          style: {
            fill: '#E3E801'
          }
        }
        this.stayingConfig = {
          number: [data.staying],
          style: {
            fill: '#ADADAD'
          }
        }
        this.totalStayingConfig = {
          number: [data.totalStaying],
          style: {
            fill: '#E77903'
          }
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.vehicle-stat-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .stat-context {
    margin-top: 6%;
    .context {
      width: 96%;
      height: 65px;
      box-sizing: border-box;
      border-radius: 5px;
      margin-left: 15px;
      margin-top: 35px;
      .dv-digital-flop {
        height: 30px;
        position: absolute;
        top: 45px;
        left:65px;
      }
      > div {
        flex: 1;
        text-align: center;
        min-width: 25%;
        > div {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 36px;
        }
        position: relative;
        display: inline-block;
        > img {
          width: 120px;
          height: 120px;
        }
      }
    }
  }
}
</style>
