<template>
  <div class="vehicle-stat-view">
    <div class="child-page-title">
      <h3>近{{ top }}天车辆出入场趋势</h3>
    </div>
    <div v-if="hasVal(vehicleLineStat)" class="stat-charts-context">
      <line-chart
        id="vehicle-line-stat"
        :val-options="vehicleLineStat"
        title=""
        unit="辆"
        y-axis-title="出入场趋势(辆/天)"
        serie-name="车辆"
      />
    </div>
    <div v-else class="stat-charts-context empty">
      暂无近{{ top }}天车辆出入场趋势
    </div>
  </div>
</template>
<script>
import { getVehicleLineStat } from '@/api/vehicle'
import lineChart from '@/components/chart/lineChart.vue'
import { hasVal } from '@/utils/index'
export default {
  name: 'VehicleLineStat',
  components: { lineChart },
  data() {
    return {
      top: 7,
      vehicleLineStat: []
    }
  },
  mounted() {
    this.removeVehicleLineStat()
  },
  methods: {
    removeVehicleLineStat() {
      this.vehicleLineStat = []
      getVehicleLineStat(this.top).then((res) => {
        const { data } = res
        if (hasVal(data.items)) {
          let _data = []
          let _name = ''
          data.items.forEach((item) => {
            _data = []
            for (const prop in item) {
              if (prop === 'Name') {
                _name = item[prop]
                delete item.Name
                break
              }
            }
            this.vehicleLineStat.push({
              name: _name,
              data: item,
              isAllData: true
            })
          })
        }
      })
    },
    hasVal(val) {
      return hasVal(val)
    }
  }
}
</script>
<style lang='scss' scoped>
.vehicle-stat-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .stat-charts-context {
    width: 99%;
    padding-left: 0.5%;
  }
}
</style>
