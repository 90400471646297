<template>
  <div class="vehicle-stat-view">
    <div class="child-page-title">
      <h3>{{ title }}</h3>
    </div>
    <div class="vehicle-info-context">
      <div class="vehicle-img">
        <el-image :src="formData.imgPath" class="vehicle-img-context">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline" />
          </div>
        </el-image>
      </div>
      <div class="vehicle-info">
        <div class="oddRowBGC">
          <div>识别设备</div>
          {{ formData.deviceName }}
        </div>
        <div>
          <div>车牌号码</div>
          {{ formData.license }}
        </div>
        <div class="oddRowBGC">
          <div>车牌颜色</div>
          {{ formData.color }}
        </div>
        <div>
          <div>{{ isIn ? "入场" : "出场" }}时间</div>
          {{ formData.time }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getVehicleInOutList } from '@/api/vehicle'
import { parseTime } from '@/utils/index'
export default {
  name: 'VehicleInOutView',
  props: {
    title: {
      type: String,
      default: '车辆实时数据'
    },
    isIn: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      formData: {
        imgPath: null,
        deviceName: null,
        license: null,
        color: null,
        time: null
      }
    }
  },
  mounted() {
    this.removeVehicleInOutInfo()
  },
  methods: {
    removeVehicleInOutInfo() {
      getVehicleInOutList(this.isIn).then((res) => {
        const { data } = res
        this.formData = {
          imgPath: data.imgPath,
          deviceName: data.deviceName,
          license: data.license,
          color: data.color,
          time: parseTime(data.time, '{y}-{m}-{d} {h}:{i}:{s}')
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.vehicle-stat-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .vehicle-info-context {
    margin-top: 10px;
    .vehicle-img {
      height: 300px;
      .vehicle-img-context {
        width: 90%;
        margin-left: 5%;
        height: 90%;
      }
    }
    .vehicle-info {
      margin-top: 5px;
      > div {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
        display: flex;
        margin-left: 5%;
        width: 90%;
        > div {
          margin-left: 5%;
          width: 30%;
          color: #53c0f6;
        }
      }
      .oddRowBGC {
        background: rgba(0, 255, 255, 0.2);
      }
    }
  }
}
</style>
