<template>
  <div class="head-ztb">
    <div class="head-ztb-title" @click="goBack" v-if="isGoBack">
      <i class="el-icon-arrow-left" /> 返回项目主页
    </div>
    <div class="head-ztb-tabs" :class="isGoBack?'':'head-ztb-tabs-margin'">
      <h2>
        <span> {{ title }}</span>
      </h2>
    </div>
    <div v-if="timerShow" class="m-l-a last-box f f-a-c">
      <span class="t-s">{{ `设备切换倒计时：${time}` }}<span>s</span></span>
    </div>
  </div>
</template>
<script>
export default {
  name: "ChildPageHead",
  props: {
    timerShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    timeNum: {
      type: Number,
      default: 0,
    },
    isGoBack: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      time: 0,
      timer: null,
    };
  },
  watch: {
    timeNum(val) {
      this.handleTime();
    },
  },
  mounted() {
    // this.handleTime();
  },
  // 销毁定时器
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); // 在Vue实例销毁前，清除时间定时器
    }
  },
  methods: {
    /**
     * 关闭当前页面
     */
    goBack() {
      if (!this.isGoBack) return;
      this.$router.push({ path: "/project" });
    },
    /**
     * 倒计时
     */
    handleTime() {
      this.time = this.timeNum;
      this.$nextTick(() => {
        this.timer = setInterval(() => {
          if (this.time == 1) {
            this.time = this.timeNum;
          } else {
            this.time -= 1;
          }
        }, 1000);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.head-ztb {
  width: 100%;
  background-image: url(../assets/image/top-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 110px;
  display: flex;
  &-title {
    color: #fff;
    font-size: 24px;
    width: 45%;
    line-height: 110px;
    margin-left: 20px;
    cursor: pointer;
  }
  &-title:hover {
    color: #209ae3;
  }
  &-tabs {
    font-size: 36px;
    line-height: 110px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    align-items: center;
    justify-content: space-between;

    > h2 {
      font-size: 36px;
      line-height: 110px;

      > span {
        background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 1) 40%,
          rgba(0, 255, 255, 1)
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bold;
      }
    }
  }
  &-tabs-margin {
    margin: 0 auto;
  }
}
.last-box {
  width: 400px;
  // margin-left: 100px;
}
.t-s {
  font-family: "微软雅黑";
  font-size: 20px;
  color: #fff;
}
</style>
