<template>
  <div class="vehicle-stat-view">
    <div class="child-page-title">
      <h3>车牌颜色</h3>
    </div>
    <div class="stat-context">
      <div class="l">
        <pie-chart
          id="vehicle-color-pie"
          :val-options="stateOptions"
          title="车牌颜色分布"
          unit="辆"
          serie-name="车辆"
        />
      </div>
      <div class="r">
        <dv-scroll-board :config="config" class="dv-scroll-board" />
      </div>
    </div>
  </div>
</template>
<script>
import { getVehiclesColorStats } from '@/api/vehicle'
import pieChart from '@/components/chart/pieChart.vue'
import { hasVal } from '@/utils/index'
export default {
  name: 'VehiclesColorStatView',
  components: { pieChart },
  data() {
    return {
      stateOptions: [],
      config: null,
      columnWidth: [45, 110, 70],
      licenseColors: [
        '#0000FF',
        '#FFFF00',
        '#FFFFFF',
        '#000000',
        '#008000',
        '#00FFFF'
      ],
      aligns: ['right', 'left', 'center']
    }
  },
  mounted() {
    this.removeVehiclesColorStat()
  },
  methods: {
    removeVehiclesColorStat() {
      this.stateOptions = []
      getVehiclesColorStats().then((res) => {
        const { data } = res
        const _data = []
        let _itemData = []
        if (hasVal(data.items)) {
          data.items.forEach((item) => {
            _itemData = []
            const _color = this.licenseColors[
              item.colorEnum > this.licenseColors.length
                ? 5
                : item.colorEnum - 1
            ]
            _itemData.push(
              "<div style='width:16px;height:16px;border-radius:16px;margin-top: 10px;background:" +
                _color +
                "'></div>"
            )
            _itemData.push(
              "<span style='color:" +
                _color +
                "'>" +
                item.color.replace('色', '牌') +
                '</span> | <span>' +
                (hasVal(item.goRate) ? item.goRate : '0%') +
                '</span>'
            )
            _itemData.push(item.goNum + '辆')
            if (Number(item.goNum) > 0) {
              this.stateOptions.push({
                text: item.color.replace('色', '牌'),
                value: item.goNum
              })
            }
            _itemData.push(item.workingHour)
            _data.push(_itemData)
          })
        }
        this.config = {
          columnWidth: this.columnWidth,
          data: _data,
          rowNum: 5,
          align: this.aligns,
          evenRowBGC: '',
          oddRowBGC: 'rgba(0,255,255,.2)'
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.vehicle-stat-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .stat-context {
    display: flex;
    margin-top: 10px;
    .l {
      flex: 1;
      text-align: center;
      border-right: 1px dashed #0e77ca9e;
    }
    .r {
      flex: 1;
      text-align: center;
      margin-top: 50px;
      .dv-scroll-board {
        height: 220px;
      }
    }
  }
}
</style>
