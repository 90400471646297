<template>
  <div class="home">
    <child-page-head :title="title" />
    <div class="home-top-main" />
    <!-- 方块组件 -->
    <drawer-vehicle>
      <!-- 左侧内容-上 -->
      <div slot="leftDrawerTopContent">
        <left-drawer-top-content />
      </div>
      <!-- 中间部分-上 -->
      <div slot="MiddleDrawerTopContent">
        <middle-drawer-top-content />
      </div>
      <!-- 右侧内容-上 -->
      <div slot="rightDrawerTopContent">
        <right-drawer-top-content />
      </div>
      <!-- 左侧内容-下 -->
      <div slot="leftDrawerBottomContent">
        <left-drawer-bottom-content title="实时入场车辆信息" :is-in="true" />
      </div>
      <!-- 中间部分-下 -->
      <div slot="MiddleDrawerBottomContent">
        <left-drawer-bottom-content title="实时出场车辆信息" :is-in="false" />
      </div>
      <!-- 右侧内容-下 -->
      <div slot="rightDrawerBottomContent">
        <right-drawer-bottom-content />
      </div>
    </drawer-vehicle>
  </div>
</template>
<script>
import childPageHead from '@/components/child-page-head'
import drawerVehicle from '@/components/drawer-vehicle.vue'
import leftDrawerTopContent from './components/left-drawer-top-content.vue'
import rightDrawerTopContent from './components/right-drawer-top-content.vue'
import leftDrawerBottomContent from './components/left-drawer-bottom-content.vue'
import rightDrawerBottomContent from './components/right-drawer-bottom-content.vue'
import middleDrawerTopContent from './components/middle-drawer-top-content.vue'
export default {
  name: 'VehicleHome',
  components: {
    childPageHead,
    drawerVehicle,
    leftDrawerTopContent,
    rightDrawerTopContent,
    leftDrawerBottomContent,
    rightDrawerBottomContent,
    middleDrawerTopContent
  },
  data() {
    return {
      title: '车辆管理'
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  background: url(../../assets/image/body-bg.jpg) no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 1000px;
  zoom: 1;
  &-top-main {
    padding: 0 13px;
    display: flex;
    justify-content: space-between;
    height: "calc(100vh - 102px)";
  }
}
</style>
