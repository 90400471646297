<template>
  <div class="x-bar" style="height: 310px">
    <div :id="id" :option="option" style="height: 310px" />
  </div>
</template>
<script>
import Highcharts from 'highcharts/highstock'
import HighchartsMore from 'highcharts/highcharts-more'
import { hasVal } from '@/utils/index'
HighchartsMore(Highcharts)
export default {
  name: 'Pie',
  props: {
    id: {
      type: String
    },
    valOptions: {
      type: Array,
      default: null
    },
    title: {
      type: String,
      default: '统计图'
    },
    serieName: {
      type: String,
      default: '统计图'
    },
    unit: {
      type: String,
      default: '人'
    }
  },
  data() {
    return {
      option: null
    }
  },
  watch: {
    valOptions(val) {
      this.load()
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    /** 数据初始化 */
    load() {
      if (!hasVal(this.valOptions)) return
      const _data = []
      this.valOptions.forEach((item) => {
        if (hasVal(_data) && _data.length > 0) {
          _data.push({
            name: item.text,
            y: item.value
          })
        } else {
          _data.push({
            name: item.text,
            y: item.value,
            sliced: true,
            selected: true
          })
        }
      })
      this.showPie(_data)
    },
    showPie(_data) {
      const _that = this
      _that.option = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          backgroundColor: '',
          type: 'pie'
        },
        title: {
          text: _that.title,
          style: {
            fontSize: '20px',
            fontFamily: 'Microsoft YaHei',
            fontWeight: '300',
            color: '#FFFFFF'
          }
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.y}' + _that.unit + '</b>'
        },
        credits: {
          enabled: false
        },
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'top',
          borderWidth: 0,
          itemStyle: {
            fontSize: '14px',
            fontFamily: 'Microsoft YaHei',
            fontWeight: 300,
            lineHeight: '18px',
            opacity: 0.8,
            color: '#FFFFFF'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              distance: -30,
              formatter: function() {
                return this.y + _that.unit
              },
              style: {
                fontWeight: 300,
                color: '#FFFFFF'
              }
            },
            showInLegend: true
          }
        },
        series: [
          {
            name: this.serieName,
            colorByPoint: true,
            data: _data,
            colors: [
              '#fd5d05',
              '#fad508',
              '#7ecef4',
              '#7c9dfe',
              '#875dc1',
              '#1d7dd3'
            ]
          }
        ]
      }
      Highcharts.chart(this.id, this.option)
    }
  }
}
</script>
