<template>
  <div class="vehicle-list-view">
    <div class="child-page-title">
      <h3>车辆实时数据</h3>
    </div>
    <div v-if="hasVal(config)" class="vehicle-list-context">
      <dv-scroll-board :config="config" class="dv-scroll-board" />
    </div>
    <div v-else class="stat-charts-context empty">暂无车辆实时数据</div>
  </div>
</template>
<script>
import { getVehiclePassList } from '@/api/vehicle'
import { hasVal, parseTime } from '@/utils/index'
export default {
  name: 'VehiclePassList',
  data() {
    return {
      top: 50,
      config: null,
      header: [
        '车牌号',
        '车牌颜色',
        '识别设备',
        '进场时间',
        '入场图',
        '出场时间',
        '出场图',
        '停留/H'
      ],
      columnWidth: [86, 80, 124, 158, 80, 158, 80, 80],
      aligns: [
        'center',
        'center',
        'center',
        'center',
        'center',
        'center',
        'center',
        'center'
      ]
    }
  },
  mounted() {
    this.removeVehiclePassList()
  },
  methods: {
    removeVehiclePassList() {
      getVehiclePassList(this.top).then((res) => {
        const { data } = res
        const _data = []
        let _itemData = []
        if (hasVal(data.items)) {
          data.items.forEach((item) => {
            _itemData = []
            _itemData.push(item.license)
            _itemData.push(item.color)
            _itemData.push(item.deviceName)
            _itemData.push(
              parseTime(item.checkInTime, '{y}-{m}-{d} {h}:{i}:{s}')
            )
            _itemData.push(
              "<img style='height:80%;width:85%;margin-top:10%;' src='" +
                item.checkInPath +
                "' />"
            )
            _itemData.push(
              parseTime(item.checkOutTime, '{y}-{m}-{d} {h}:{i}:{s}')
            )
            if (hasVal(item.lastCheckInPath)) {
              _itemData.push(
                "<img style='height:80%;width:85%;margin-top:10%;' src='" +
                  item.lastCheckInPath +
                  "' />"
              )
            } else {
              _itemData.push('')
            }
            _itemData.push(item.workingHour)
            _data.push(_itemData)
          })
          this.config = {
            header: this.header,
            columnWidth: this.columnWidth,
            align: this.aligns,
            data: _data,
            rowNum: 9,
            headerHeight: 50,
            headerBGC: 'rgba(0,255,255,.3)',
            evenRowBGC: '',
            oddRowBGC: 'rgba(0,255,255,.2)'
          }
        }
      })
    },
    hasVal(val) {
      return hasVal(val)
    }
  }
}
</script>
<style lang='scss' scoped>
.vehicle-list-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .vehicle-list-context {
    margin-top: 20px;
    .dv-scroll-board {
      height: 492px;
    }
  }
}
</style>
