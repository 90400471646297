<template>
  <!-- 方块组件 -->
  <div class="drawer-box-block">
    <!-- 上方方块 -->
    <div class="drawer-box-top">
      <!-- 左侧上半部分 -->
      <dv-border-box-7
        :color="['#10568e', 'rgb(124, 231, 253)']"
        class="drawer-box-left-top"
      >
        <slot name="leftDrawerTopContent" />
      </dv-border-box-7>
      <!-- 中间上半部分 -->
      <dv-border-box-7
        :color="['#10568e', 'rgb(124, 231, 253)']"
        class="drawer-box-middle-top"
      >
        <slot name="MiddleDrawerTopContent" />
      </dv-border-box-7>
      <!-- 右侧上半部分 -->
      <dv-border-box-7
        :color="['#10568e', 'rgb(124, 231, 253)']"
        class="drawer-box-right-top"
      >
        <slot name="rightDrawerTopContent" />
      </dv-border-box-7>
    </div>
    <!-- 下方方块 -->
    <div class="drawer-box-bottom">
      <!-- 左侧下半部分 -->
      <dv-border-box-7
        :color="['#10568e', 'rgb(124, 231, 253)']"
        class="drawer-box-left-bottom"
      >
        <slot name="leftDrawerBottomContent" />
      </dv-border-box-7>
      <!-- 中间下部分 -->
      <dv-border-box-7
        :color="['#10568e', 'rgb(124, 231, 253)']"
        class="drawer-box-middle-bottom"
      >
        <slot name="MiddleDrawerBottomContent" />
      </dv-border-box-7>
      <!-- 右侧下半部分 -->
      <dv-border-box-7
        :color="['#10568e', 'rgb(124, 231, 253)']"
        class="drawer-box-right-bottom"
      >
        <slot name="rightDrawerBottomContent" />
      </dv-border-box-7>
    </div>
  </div>
</template>
<script>
export default {
  name: "DrawerInspection",
};
</script>
<style lang="scss" scoped>
.drawer-box-block {
  .drawer-box-top {
    height: 360px;
    width: 100%;
    display: flex;
    margin-top: 20px;
    z-index: 1;
    opacity: 0.92;
    margin-left: 15px;
    .drawer-box-left-top {
      width: 825px;
      height: 100%;
      border: 0px;
      background: rgba(30, 32, 43, 0.04);
      opacity: 0.8;
    }
    .drawer-box-middle-top {
      width: 519px;
      height: 100%;
      margin-left: 12px;
      background: rgba(100, 116, 211, 0.04);
      border: 0px;
      opacity: 0.8;
    }
    .drawer-box-right-top {
      width: 519px;
      margin-left: 12px;
      height: 100%;
      background: rgba(100, 116, 211, 0.04);
      border: 0px;
      opacity: 0.8;
    }
  }
  .drawer-box-bottom {
    width: 100%;
    margin-top: 12px;
    margin-left: 15px;
    height: 552px;
    display: flex;
    z-index: 1;
    opacity: 0.92;
    .drawer-box-left-bottom {
      width: 500px;
      height: 100%;
      border: 0px;
      background: rgba(100, 116, 211, 0.04);
      opacity: 0.8;
    }
    .drawer-box-middle-bottom {
      width: 500px;
      margin-left: 12px;
      height: 100%;
      border: 0px;
      background: rgba(100, 116, 211, 0.04);
      opacity: 0.8;
    }
    .drawer-box-right-bottom {
      background: rgba(100, 116, 211, 0.04);
      margin-left: 12px;
      width: 863px;
      height: 100%;
      border: 0px;
      opacity: 0.8;
    }
  }
}
</style>